<template>
<div class="min-h-screen flex flex-col w-full" style="background-color: #F8F8F8">
    <LayoutHeader/>
    <div class="mx-auto container">
        <slot/>
    </div>
    <LayoutCheckoutFooter />
</div>
</template>
<script lang="ts">
export default {
    name: "checkout",
};
</script>
<script setup lang="ts">
// Navigation for default theme
import LayoutHeader from "~/components/layout/LayoutCheckoutHeader.vue";
import LayoutCheckoutFooter from "~/components/layout/LayoutCheckoutFooter.vue";
</script>
